<template>
    <div class="index">
        <!-- 曼扉心理 -->
        <div class="banner">
            <img v-if="!$store.state.isPhone" :src="$store.state.bannerUrl + $store.state.bannerImgs.dcmf" alt="">
            <img v-else src="../../assets/images/banner/了解我们banner备份 2.jpg" alt="" />
            <!-- <img :src="$store.state.bannerUrl + $store.state.bannerImgs.dcmf" alt=""> -->
        </div>
        <div :class="!$store.state.isPhone ? 'main' : 'phoneStyle main'">
            <!-- 曼扉心理 -->
            <div class="dcmf">
                <div class="titleContent">
                    <p class="p1">曼扉心理</p>
                    <span class="span1">BRIEF INTRODUCTION</span>
                </div>
                <div class="dcmfContent">
                    <div class="PromptText">
                        <p class="p2 addp">关于曼扉心理</p>
                        <span class="span2">
                            曼扉心理是一家专注于中高端医学的心理健康服务品牌，由知名精神科专家和心理学教授联合创立。
                            <br />
                            自2020 年成立以来，曼扉心理立足于四川成都，构建了以门诊医疗服务、心理服务为主，辅以住院治疗、社区康复的完整心理医疗服务体系。<br />
                            曼扉心理是提供希望的场所。<br />
                            曼扉心理相信，最好的药物是希望。<br />
                            希望，往往是人与深渊之间唯一的东西。<br />
                            只要我们存在希望，我们就可以从任何事情中恢复过来。<br />
                        </span>
                    </div>
                    <div class="PromptText">
                        <p class="p2 addp">曼扉心理诊所</p>
                        <span class="span2">

                            曼扉心理诊所位于成都市锦江区庆云南街115号，占地600多平方米，是成都市规模最大的心理卫生诊所。曼扉心理诊所由前华西医院心理卫生中心资深医师主持医务工作，广泛地吸纳各方面心理卫生服务专业人才，组建业内顶级医疗服务团队，形成了“医疗-心理-康复社会工作”的整体医疗模式，为川内广大民众带来优质、便捷、个性化的、以患者为中心的心理卫生服务。在服务理念上，曼扉心理诊所除了传统的药物治疗外，更注重心理疗愈和健康的生活方式，相信患者有自愈的本能，充分挖掘求助者自身资源，时刻给予求助者以希望，最大化的发挥其能力，促进起全面复元。
                        </span>
                    </div>
                    <div class="imgs">
                        <div class="img">
                            <div class="item" v-for="item in dcmfList" :key="item.id">
                                <img :src="require(`../../assets/images/dcmf/${item.path}`)" alt="">
                                <span class="span2">{{ item.name }}</span>
                            </div>
                        </div>
                        <!-- <div class="img">
                            <div class="item" v-for="item in dcmfList.slice(4, 8)" :key="item.id">
                                <img :src="require(`../../assets/images/dcmf/${item.path}`)" alt="">
                                <span class="span2">{{ item.name }}</span>
                            </div>

                        </div> -->


                    </div>
                </div>
            </div>
            <!-- 团队简介 -->
            <div class="team">
                <div class="titleContent">
                    <p class="p1">团队简介</p>
                    <span class="span1">Team profile</span>
                </div>
                <div class="teamContent">
                    <div class="PromptText">
                        <span class="span2">
                            曼扉心理诊所是成都市规模最大的心理卫生诊所，有行业内知名人士坐镇，拥有多名博士生导师和硕士导师，华西医院著名教授、省社会科学院教授，著名的治疗师和咨询师。希望为患者带来治疗效果和希望，回归健康生活，致力于满足精神心理领域中高端需求。
                        </span>
                    </div>
                    <div class="teamTitleContent">
                        <div class="left"></div>
                        <div class="right">
                            <p class="p2">核心团队</p>
                            <span class="span2">Core team</span>
                        </div>
                    </div>
               
                    <div class="doctorContent">
                        <div class="item" v-for="item in doctorList" :key="item.id">
                        <div class="right">
                                <img :src="require(`../../assets/images/dcmf/${item.imgPath}`)" alt="">
                            </div>
                        <div class="left">
                                <p class="p3">
                                    <span class="span2">{{ item.name }}</span>
                                    {{ item.title }}
                                </p>
                                <ul>
                                    <li v-for="i in item.list" :key="i.id">{{ i.text }}</li>
                                   
                                </ul>
                            </div>
                            </div>
                           
                    </div>
                    <div class="teamTitleContent teamTitleContent2">
                        <div class="left"></div>
                        <div class="right">
                            <p class="p2">我们的伙伴</p>
                            <span class="span2">Our partner</span>
                        </div>
                    </div>
                    <div class="expertContent">
                        <div class="item" v-for="item in expertList" :key="item.id">
                            <div class="left">
                                <div class="img">
                                    <span class="span2">{{ item.name }}</span>
                                    <img :src="require(`../../assets/images/dcmf/${item.imgPath}`)" alt="">
                                </div>
                            </div>
                            <div class="right">
                                <p class="p3"><span class="span2">执业经验：</span>{{ item.qualifications }}
                                </p>
                                <p class="p3"><span class="span2">心理寄语：</span>{{ item.Message }}</p>
                            </div>
                            <div class="bottomStyle"></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isPc: true,
            dcmfList: [
                { id: 1, path: '编组 6@2x.png', name: '前台大厅' },
                { id: 2, path: '编组 6@2x.png', name: '大厅' },
                { id: 3, path: '编组 3@2x.png', name: '咨询室' },
                { id: 4, path: '编组 4@2x.png', name: '茶室' },
                { id: 5, path: '编组 22@2x.png', name: '会议室' },
                { id: 6, path: '编组 23@2x.png', name: '测试环境' },
                { id: 7, path: '编组 24@2x.png', name: '测试环境' },
                { id: 8, path: '编组 25@2x.png', name: '楼廊一隅' },
            ],
            doctorList: [
                {
                    id: 1, name: '谢靓', title: '曼扉心理品牌创始人', imgPath: 'doctor1.png', list: [
                        { id: 1, text: '成都榕爱社会服务工作中心副理事长' },
                        { id: 2, text: '中国药物滥用防治协会青年专委会委员' },
                        { id: 3, text: '社会心理干预分会委员兼总干事' },
                        { id: 4, text: '四川省预防医学会未成年人心理健康分会会员' },
                        { id: 5, text: '四川省医学传播学会心理健康分会会员' },
                        { id: 6, text: '成都理工大学、四川旅游学院外聘教师' },
                        { id: 7, text: '原四川大学华西医院心理卫生中心主治医师' }
                    ]
                },
                {
                    id: 2, name: '杨诗祎', title: '曼扉心理总经理', imgPath: 'doctor2.png', list: [
                        { id: 1, text: '管理学硕士' },
                        { id: 2, text: '12年金融机构从业经验' },
                        { id: 3, text: '曾任世界500强金融机构区域负责人，渠道负责人' },
                        { id: 4, text: 'PMP项目管理持证人' },
                        { id: 5, text: '心理咨询师' },
                        // { id: 6, text: '成都理工大学、四川旅游学院外聘教师' },
                        // { id: 7, text: '原四川大学华西医院心理卫生中心主治医师' }
                    ]
                },
                {
                    id: 3, name: '胡珉', title: '曼扉心理诊所首席精神科医师', imgPath: 'doctor3.png', list: [
                        { id: 1, text: '曼扉心理诊所精神科副主任医师' },
                        { id: 2, text: '四川大学华西临床医学院精神病学' },
                        { id: 3, text: '四川大学华西临床医学院精神卫生学专业硕士' },
                        { id: 4, text: '具有近20年服务于三甲综合医院精神科及三甲精神专科医院的经验。擅长各类成人常见精神心理问题的诊疗。' }
                    ]
                },
                {
                    id: 4, name: '高哲', title: '曼扉心理诊所精神科医师', imgPath: 'doctor4.png', list: [
                        { id: 1, text: '曼扉心理诊所精神科主治医师' },
                        { id: 2, text: '曼扉心理诊所中级心理治疗师' },
                        { id: 3, text: '四川大学华西临床医学院精神病学' },
                        { id: 4, text: '四川大学华西临床医学院精神卫生学专业硕士' },
                        { id: 5, text: '具有服务于三甲综合医院精神科及三甲精神专科医院的经验。擅长各类成人常见精神心理问题的诊疗，尤其擅长睡眠医学及性相关问题的诊疗和咨询。' }
                    ]
                },
                {
                    id: 5, name: '余良明', title: '曼扉心理诊所精神科医师', imgPath: 'doctor5.png', list: [
                        { id: 1, text: '性少数友好咨询师' },
                        { id: 2, text: '心理咨询师' },
                        { id: 3, text: '擅长目标导向的心理咨询、精神疾病康复、焦虑抑郁的分析与治疗、青少年成长及性心理咨询。' },
                        // { id: 4, text: '四川大学华西临床医学院精神卫生学专业硕士' },
                        // { id: 5, text: '具有服务于三甲综合医院精神科及三甲精神专科医院的经验。擅长各类成人常见精神心理问题的诊疗，尤其擅长睡眠医学及性相关问题的诊疗和咨询。' }
                    ]
                },
                {
                    id: 6, name: '毛晓雨', title: '曼扉心理诊所心理医师', imgPath: 'doctor6.png', list: [
                        { id: 1, text: '心理医师，应用学心理硕士' },
                        { id: 2, text: '成都朗德教育心理成长专家' },
                        { id: 3, text: '四川省应用心理学研究中心研究员' },
                        { id: 4, text: '擅长运用认知行为治疗技术、绘画治疗技术、沙盘治疗技术及短程焦点治疗，为来访者提供成长和帮助。' },
                        // { id: 5, text: '具有服务于三甲综合医院精神科及三甲精神专科医院的经验。擅长各类成人常见精神心理问题的诊疗，尤其擅长睡眠医学及性相关问题的诊疗和咨询。' }
                    ]
                },
                {
                    id: 7, name: '李悠寒', title: '曼扉心理诊所首席心理治疗师', imgPath: 'doctor7.png', list: [
                        { id: 1, text: '心理治疗师，国家三级心理咨询师。' },
                        { id: 2, text: '心理动力学、认知行为学受训背景。' },
                        { id: 3, text: '儿童注意缺陷多动障碍及其父母的团体心理咨询与治疗，儿童青少年情绪、行为问题，LGBTQ人群性别与性倾向认同，亲子关系，亲密关系问题，个人成长等。' },
                        // { id: 4, text: '四川大学华西临床医学院精神卫生学专业硕士' },
                        // { id: 5, text: '具有服务于三甲综合医院精神科及三甲精神专科医院的经验。擅长各类成人常见精神心理问题的诊疗，尤其擅长睡眠医学及性相关问题的诊疗和咨询。' }
                    ]
                },
            ],
            expertList: [
                { id: 8, name: '臧肖', imgPath: 'expert8.png', qualifications: '长期从事儿童社会工作、社会政策领域的教学、科研、实务及督导工作;长期参与四川省、成都市儿童之家建设的督导工作;参与《四川省儿童发展纲要(2021-2030)》的编写。', Message: '心理咨询是自我探索的旅程,愿陪你此段路程——在艰辛和苦难中成长，收获更自在的人生。' },
                { id: 10, name: '孙璐', imgPath: 'expert10.png', qualifications: '西南师范大学(现西南大学 )“应用心理学专业”硕士研究生，副教授，国家二级心理咨询师。一直在高校从事教学、科研和咨询工作。同时，长期在各级教育单位、社区、企事业单位从事心理健康培训和咨询服务工作。对认知行为疗法、团体心理咨询进行过较为深入、系统的学习。个案累积时长500+小时，团体辅导和咨询300+小时。持续接受个案督导和个人体验。', Message: '你的过去不应该定义你的未来，相信你可以创造一个更好的未来。' },
                { id: 11, name: '徐云', imgPath: 'expert11.png', qualifications: '毕业于四川大学，研究方向为精神分析与心理治疗。', Message: '不要害怕表达自己的情感，它们是你的一部分。' },
                { id: 12, name: '吴颖', imgPath: 'expert12.png', qualifications: '四川大学应用心理学硕士国家二级心理咨询师、心理治疗师、成都某高校心理专业教师成都某高中心理健康顾问。12年心理咨询专业学习与实践，倾人本主义体验，识认知行为治疗力量;个案累计时长1200+小时，团体咨询300+小时，持续接受一周一次的一对一督导和团体督导。', Message: '你的情感和感受都是真实的，不要否认或压抑它们。' },
                { id: 13, name: '李杨', imgPath: 'expert13.png', qualifications: '西南大学基础心理学硕士，在高校从事心理学教学、科研和大学生心理咨询工作。长期在各级教育单位、企事业单位从事心理健康培训和咨询服务工作。', Message: '不要害怕面对挑战，它们是让你成长的机会。' },
                { id: 14, name: '赵亮', imgPath: 'expert14.png', qualifications: '曾参加中国心理学会注册系统伦理培训、四川大学华西医院心理卫生中心心理咨询执业培训，系统参加了华西医院认知行为治疗(CBT)连续培养项目、中国心理卫生学会EMDR创伤治疗连续培养项目、上海市精神卫生中心辩证行为治疗(DBT)连续培养项目、艾利克森临床催眠连续培养项目、沈家宏系统动力式家庭治疗连续培养项目、李鸣精神分析连续培养。', Message: '学会宽容和谅解，它们可以缓解你的痛苦。' },
                { id: 9, name: '刘悦', imgPath: 'expert9.png', qualifications: '曼扉心理督导师，心理咨询师，四川大学应用心理学专业硕士，,师从胡冰霜教授。从业十四年，积累了大量个案分析和团体工作经验，现任成都市教育系统心理培训督导师、高新区合作卫生服务中心心理咨询师。个案累计时长1500+小时,团体咨询400+小时，持续接受一周一次的一对一督导和团体督导。', Message: '坚持自己的信仰和价值观，它们是你的精神支柱。' },

                { id: 2, name: '王洲兰', imgPath: 'expert2.png', qualifications: '近10年心理咨询专业工作,运用心理动力学技术、沙盘技术、绘画技术、催眠及正念技术，和来访者进行工作,咨询经验丰富，帮助很多家庭和来访者渡过困难,走向成长。个案累计时长1500+小时，团体咨询400+小时,持续接受一周一次的一对一督导和团体督导。', Message: '把我们的伤痛，变成光照进来的地方。找到内在力量，在绝境中依然有选择。' },
                { id: 5, name: '毛晓雨', imgPath: 'expert5.png', qualifications: '从事多年心理咨询和辅导工作,曾于三甲精神专科医院从事心理治疗专业学习实践,积累丰富神经心理测评和临床心理治疗经验。个案咨询累积时长1000小时以上，团体咨询累积时长200小时以上。曾为成都医学院《医学心理学》课程心理咨询和心理治疗章节授课老师，参与多项省部级课题，参编《老年失能风险预警手册》，参与临床心理研究10余项，于国内核心期刊发表多篇医学论文。', Message: '不要害怕表达自己的需求和愿望，它们是你实现幸福的关键。' },
                { id: 15, name: '李悠寒', imgPath: 'expert15.png', qualifications: '心理治疗师，国家三级心理咨询师。心理动力学、认知行为学受训背景。接受个人及团体体验270+小时，个人及团体督导70+小时，个案累计时长200+小时，团体心理咨询110+小时。擅长运用心理动力、认知行为、短程焦点等技术与来访者一同工作。', Message: '对错之外，还有一片广袤的原野。我愿与你共同寻找其所在。' },
                { id: 7, name: '徐榕婧', imgPath: 'expert7.png', qualifications: '从事心理咨询工作10余年,累计个案咨询时间1000+小时。曾参加四川大学华西医院心理卫生中心心理咨询师进修;EFT（情绪取向）婚姻家庭、个体治疗认证培训;中港灾后心理治疗培训;中澳心理危险干预培训;杨凤池分析体验式心理咨询技术培训。曾参加5·12汶川地震灾后心理康复、深圳富士康科技集团心理危机干预及康复工作。', Message: '我们的每一面都值得被看见,都有他存在的意义。' },

                { id: 1, name: '张丽芳', imgPath: 'expert1.png', qualifications: '多年心理咨询专业的学习、成长、督导和实践，擅长运用认知行为治疗技术、绘画咨询技术、焦点解决和来访者进行工作，咨询经验丰富，陪伴了很多来访者的成长，曾于四川大学华西医院临床心理治疗与咨询专业进修学习一年,个案累积时长1000+小时，团体咨询300+小时,接受个人体验100+小时,持续接受个人体验中。', Message: '所有失去的，都会以另外一种方式归来，我是心理咨询师张丽芳，我将陪着你一起去寻找！' },


                { id: 4, name: '陈嫣', imgPath: 'expert4.png', qualifications: '心理咨询师，近10年心理咨询专业工作,运用以来访者为中心技术、沙盘技术、游戏互动等进行咨询。个案累计时长1000+小时,团体咨询300+小时,持续接受一周一次的一对一督导和团体督导。完成华西心理卫生中心心理咨询临床进修。', Message: '你的内心有着无限的力量，相信自己。' },
                { id: 3, name: '刘萍', imgPath: 'expert3.png', qualifications: '心理咨询师，接受过系统化的认知行为培训，学习过情绪管理/婚姻家庭及父母效能等专业课程，华西心理卫生中心进修学习经验,个人咨询时长累计1500+小时,带领团体活动100余场。咨询方法多采用认知行为疗法/箱庭疗法/家庭治疗/意象对话等。以来访者为中心，用自身的亲和力和共情能力贴近来访者，真诚而温暖的陪伴让来访者感受到支持和安全，逐渐看到自己的力量和价值，引导来访者实现个人的突破和成长，最终充满力量地向自己的目标靠近。', Message: '你的情感和感受都是真实的，不要否认或压抑它们。' },
                { id: 6, name: '李娜', imgPath: 'expert6.png', qualifications: '曾于2018年在华西医院心理卫生中心住院部进修半年。心理动力学、存在人本及认知行为（正念)、叙事绘画等心理学培训，并在执业过程中持续接受个人体验，以及团体和个人督导，个案累计时长1000+小时,团体辅导200+小时。', Message: '自我关爱是最重要的，不要忘记照顾自己。' },



            ]
        }
    },
    created() {
        this.isPc = sessionStorage.getItem('isPc')
    }
}
</script>

<style lang="scss" scoped>
.index {
    .banner {
        img {
            width: 100%;
            height: 300px;
            // height: 500px;
        }
    }

    .main {
        display: block;

        .PromptText {
            span {
                color: var(--memoFont-color);
            }
        }

        .dcmf {
            .dcmfContent {

                .addp {
                    margin-top: 20px;
                    margin-bottom: 10px;
                    font-weight: 600;
                }

                .imgs {

                    .img {
                        display: flex;
                        flex-wrap: wrap;
                        // justify-content: space-around;

                        .item {
                            // display: inline-block;
                            // box-sizing: border-box;
                            // margin-right: 20px;
                            margin: 0px 12px;
                            margin-top: 20px;
                            position: relative;
                            width: 250px;
                            // height: 170px;

                            &:hover {
                                span {
                                    color: black;
                                    background: var(--custom-color);
                                }
                            }

                            img {
                                height: 100%;
                                width: 100%;
                            }

                            span {
                                display: inline-block;
                                position: absolute;
                                text-align: center;
                                height: 30px;
                                line-height: 30px;
                                width: 65px;
                                right: 0;
                                bottom: 0;
                                color: #FFFFFF;
                            }

                        }
                    }



                    // .no{
                    //     margin-right: 0px;
                    // }
                }
            }
        }

        .team {
            .teamContent {
                .teamTitleContent {
                    display: flex;
                    align-items: center;
                    height: 32px;
                    margin: 20px 0 28px;

                    .left {
                        width: 8px;
                        height: 100%;
                        background: var(--custom-color);
                    }

                    .right {
                        height: 100%;
                        margin-left: 5px;
                        text-align: left;
                        box-sizing: border-box;

                        p {
                            font-weight: 500;
                            // line-height: 0;
                            line-height: 18px;

                        }

                        span {
                            color: var(--spanFont-color);
                            text-transform: uppercase;
                            line-height: 20px;
                        }
                    }
                }
                

                .teamTitleContent2 {
                    margin: 0;
                    margin-bottom: 15px;
                }

                .doctorContent {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .item {
                        // width: 100%;
                        // display: flex;
                        width: 520px;
                        margin-bottom:50px;

                        &:hover {
                            cursor: pointer;

                            .left {
                                p {
                                    span {
                                        background: var(--custom-color);
                                    }
                                }
                            }
                        }

                        .left {
                            width: 350px;

                            p {
                                border-bottom: 1px solid var(--headFont-color);

                                span {
                                    display: inline-block;
                                    width: 54px;
                                    height: 26px;

                                    font-weight: 600;
                                    text-align: center;
                                    line-height: 26px;
                                }

                                color: var(--titleFont-color);
                            }

                            ul {
                                margin-top: 18px;
                                padding-left: 18px;

                                li {
                                    font-size: 14px;
                                    color: var(--memoFont-color);
                                    margin-bottom: 10px;

                                    &:last-child {
                                        margin-bottom: 0px;
                                    }
                                }
                            }
                        }

                        .right {
                            // position: relative;
                            float: right;
                            // height: 286px;
                            // width: 160px;

                            img {
                                // height: 200px;
                                width: 180px;
                                // position: absolute;
                                // top: -50px;
                            }
                        }
                    }
                }
                

                .expertContent {
                    border-top: 1px solid var(--headFont-color);

                    .item {
                        border-bottom: 1px solid var(--headFont-color);
                        display: flex;
                        padding: 20px 0;
                        position: relative;

                        &:hover {
                            border-bottom: 1px solid white;

                            .bottomStyle {
                                display: block;
                            }

                            .left {
                                span {
                                    background: var(--custom-color);
                                }
                            }
                        }

                        .left {
                            display: flex;
                            align-items: center;
                            margin-right: 38px;


                            .img {
                                position: relative;

                                img {
                                    height: 187px;
                                }

                                span {
                                    display: inline-block;
                                    position: absolute;
                                    font-weight: 600;
                                    color: var(--headFont-color);
                                    top: 0;
                                    right: 0;
                                    width: 56px;
                                    height: 26px;
                                    text-align: center;
                                    line-height: 26px;

                                }
                            }
                        }

                        .right {
                            padding-top: 58px;

                            // display: flex;
                            // // flex-wrap: wrap;
                            // align-items:center;
                            p {

                                width: 100%;
                                color: var(--memoFont-color);

                                &:first-child {
                                    // flex-wrap: wrap;
                                    margin-bottom: 20px;

                                }

                                span {
                                    color: var(--titleFont-color);
                                    font-weight: 600;
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {

    .index .phoneStyle.main {

        .dcmf {
            .dcmfContent {
                .imgs {
                    .img {
                        justify-content: space-evenly;
                        .item{
                            width: 160px;
                            margin: 0;
                            margin-top: 10px;
                        }
                        // flex-wrap: wrap;
                    }
                }
            }
        }

        .team {
            .doctorContent {
                .item {
                    display: block !important;
                    .left{
                        .p3{
                            span{
                                text-align: left;
                            }
                        }
                        // float: right;
                        // width: 300px;
                    }
                    .right {
                        // float: left;
                        img {
                            width: 150px;
                        }
                    }
                }
            }
            .expertContent {
            .item {
                display: block !important;
                .right{
                    padding-top: 10px;
                }
                .left{
                    .img{
                        img{
                            height: 150px;
                        }
                    }
                }
            }
        }
        }

       
    }
}
</style>